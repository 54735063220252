import React from "react";
import { Image } from "react-bootstrap";
import img1 from "./images/SMC-1.jpg";
import img2 from "./images/SMC-2.png";
import img3 from "./images/SMC-3.jpg";
import img4 from "./images/SMC-4.jpg";
import img5 from "./images/SMC-5.jpg";
import img6 from "./images/SMC-6.jpg";
import img7 from "./images/SMC-7.jpg";
import img8 from "./images/SMC-8.jpg";
import img9 from "./images/SMC-9.jpg";
import img10 from "./images/SMC-10.jpg";

const TheCount = () => {
  return (
    <>
      <div class="menu metropolis-extra-light">
        <a href="/">Home</a>
        <a href="/menu">Work</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
      <div class="container molting">
        <div class="row">
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8 molting-spacing">
            <div className="molting-title metropolis-thin">The count</div>
            <div className="molting-subtitle metropolis-thin">
              2023 - ongoing
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8 molting-text-justify molting-spacing metropolis-extra-light">
            During the last conversation I had with my grandmother before her
            death, I asked her about an inscription that I saw carved on a stone
            in a tortillería (the place where tortillas are made by hand and
            sold) near her house. She told me that, that cross with circles on
            each side was a symbol of fertility, life, and wealth, which
            referred to the four ends of the universe, which support the earth
            and the sky. <br />
            <br /> My maternal family is Nahua, a culture whose memory and
            identity are increasingly dissolved not only in Tlaxcala, the place
            where we come from but in my own family. My grandmother had never
            told me about this symbol before, even though its meaning was
            important to her parent's profession, who used to farm corn and sell
            pulque. <br />
            <br /> Corn, and pulque, seed and drink, both surrounded by
            mysticism, two important elements of Tlaxcala’s food system whose
            cultivation and extraction process was governed by an agricultural
            ritual cycle surrounded by stories. This region is a place with an
            incredible diversity of native corn, in parallel, pulque led to an
            economic boom and even an architectural canon is being taken
            advantage of by tourism. <br />
            <br /> This series is divided into two chapters, the first one is
            dedicated to corn, and the second to pulque. This project is about
            the knowledge that my grandparent's generation possessed and now is
            being forgotten, their practices and their stories, the calendar
            accounts organized by groups of twenty days; the days in which the
            devil wallows in the corn furrows, dances, offerings, and snakes
            that brought the rain, drunk opossums that protect pulque, weather
            shamans, lunar cycles and the observation of the cosmos, the stars,
            and the clouds.
            <br />
            <br />
            <div className="metropolis-regular" style={{ fontSize: ".7rem" }}>
              Glossary: <br /> Tortilla: Flat circular, yeast-free disk, made
              with corn dough. <br /> Pulque: a white, slimy alcoholic drink
              made from the fermentation of the water extracted from the maguey
              (agave plant) known in Mesoamerica as the Tree of Wonders.
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8">
            <Image src={img1} />
          </div>
          <div class="col">
            <div className="molting-text-justify molting-spacing metropolis-regular">
              My prayer also reaches across the entire width of the eastern side
              of the four corners of heaven and the four corners of the earth, I
              trust my prayer will safely arrive and be accepted, in the same
              way across the entire width of the southern side of the four
              corners of heaven as well as the four corners of the earth, which
              is where I see my prayer fall.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Image src={img2} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 raiz-hambre-img-center">
            <Image src={img3} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img4} style={{ paddingLeft: "0px !important" }} />
          </div>
          <div class="col-12">
            <Image src={img5} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9">
            <Image src={img6} />
          </div>
          <div class="col-3"></div>
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9">
            <Image src={img7} />
          </div>
          <div class="col-12 molting-spacing">
            <div className="metropolis-regular molting-text-right">
              <em>
                Offering ask for rain and good harvests at the tip of
                Popocatépetl, a volcano 130 km away from Tlaxcala.
              </em>
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8">
            <Image src={img8} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-4 raiz-hambre-text-center">
            <div className="molting-text-justify molting-spacing molting-text-left metropolis-regular">
              Did you believe in dreams? Did you put an offering to the
              tlacatécolotl? (Shaman Owl-man) Did you conjure downpour or hail
              with evil arts? Have you believed what you dream? Have you
              believed in animals like the owl, the opossum and other animals?
              Have you believed what dreams represent? <br /> <br />{" "}
              Interrogation carried out by Spanish priests to indigenous people
              in Mexico, during the 18th century.
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img9} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img10} />
          </div>
          <div class="col-12 molting-spacing"></div>
        </div>
      </div>
    </>
  );
};

export default TheCount;
