import React from "react";
import imageHome from "../../resources/SMC_2.jpg";
import { Image } from "react-bootstrap";
import MenuHeader from "../../components/menuHeader";

const About = () => {
  return (
    <>
      <MenuHeader current="About" />
      <div className="center-screen">
        <div class="container">
          <div class="row">
            <div class="col-12 col-xs-12 col-sm-12 col-lg-6">
              <div className="mx-auto text-center">
                <Image className="about-image" src={imageHome} />
              </div>
            </div>
            <div class="col about-text-container">
              <div>
                <div className="about-text metropolis-regular">
                  Tlaxcala, Mexico. 1995.
                </div>
                <div className="about-text metropolis-regular">
                  My work is focused on studying my blood’s memory, I’m the
                  daughter of a mestizo father and a Nahua mother, and my
                  interests lie in the study of identity, territory, orality,
                  pain and rituality within my personal history. <br />
                  <br /> The night as a space of time full of blind spots where
                  the hidden reigns and what is socially correct is transgressed
                  is an assiduous concern in my gaze, I am drawn to the visceral
                  aspects of human existence. Writing, and especially poetry is
                  constantly accompany my work, serving as a guide to
                  investigate and understand my concerns. <br />
                  <br /> Currently, by giving workshops, lectures and exhibiting
                  my photographs in schools and cultural centers in rural areas
                  of Tlaxcala, I seek for my work to return and live in the
                  spaces and people that forged my vision of the world;
                  decentralize art, taking photography to spaces where there are
                  no museums or galleries is an ethical pillar for me. <br />
                  <br /> I studied a BA in Craft Design (2013-2017) at the
                  University of Guadalajara with complementary study residences
                  in Art History and Social Anthropology (2018). In 2020, I
                  completed the International Diploma in Cultural Heritage from
                  the National Institute of Anthropology and History (INAH). I
                  attended photography workshops at the San Agustín Arts Center
                  founded by artist Francisco Toledo in Etla, Oaxaca. In 2022, I
                  graduated from the Photographic Production Seminar of the
                  Centro de la Imagen and I was a beneficiary of the Young
                  Creators Scholarship Award of the National Fund for Culture
                  and the Arts (FONCA) in the photography category. In 2023, my
                  work was part of the exhibition <em>La Materia</em> que se{" "}
                  <em>Borra</em> at the Centro de la Imagen, in this same year,
                  I was selected for the Trasatlántica program of Photo España
                  and I got the loan of a camera as a prize from the portfolio
                  review of the Mirar Distinto International Photography
                  Festival in collaboration with Leica Mexico.
                  <br />
                  <br /> Sara Meneses Cuapio © All Rights Reserved <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
