import React from "react";
import imageTheCount from "../../resources/img_the_count.jpg";
import imageRaizHambre from "../../resources/img_raiz_hambre.jpg";
import imageMoultTime from "../../resources/img_moult_time.jpg";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import MenuHeader from "../../components/menuHeader";

const Menu = () => {
  return (
    <>
      <MenuHeader current="Work" />
      <div className="center-screen">
        <section className="gallery">
          <div className=" container-lg">
            <div className="row gy-4 row-cols-1  row-cols-sm-2 row-cols-md-3">
              <div className="col">
                <Link to="/molting-time">
                  <Image className="gallery-item" src={imageMoultTime} />
                </Link>
                <div className="gallery-text metropolis-regular">
                  <a href="/molting-time">Molting time</a>
                </div>
              </div>
              <div className="col">
                <Link to="/raiz-hambre">
                  <Image className="gallery-item" src={imageRaizHambre} />
                </Link>
                <div className="gallery-text metropolis-regular">
                  <a href="/raiz-hambre">Raízhambre</a>
                </div>
              </div>
              <div className="col">
                <Link to="/the-count">
                  <Image className="gallery-item" src={imageTheCount} />
                </Link>
                <div className="gallery-text metropolis-regular">
                  <a href="/the-count">The count</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Menu;
