import React from "react";

const Title = ({ value }) => {
  return (
    <>
      <div className="title">
        <p className="metropolis-extra-light">{value}</p>
      </div>
    </>
  );
};

export default Title;
