import React from "react";
import { Image } from "react-bootstrap";
import img1 from "./images/SMC-1.jpg";
import img2 from "./images/SMC-2.jpg";
import img3 from "./images/SMC-3.jpg";
import img8 from "./images/SMC-8.jpg";
import img9 from "./images/SMC-9.jpg";
import img10 from "./images/SMC-10.jpg";
import img11 from "./images/SMC-11.jpg";
import img12 from "./images/SMC-12.jpg";
import img13 from "./images/SMC-13.jpg";
import img14 from "./images/SMC-14.jpg";
import img22 from "./images/SMC-22.jpg";

const RaizHambre = () => {
  return (
    <>
      <div class="menu metropolis-extra-light">
        <a href="/">Home</a>
        <a href="/menu">Work</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
      <div class="container raiz-hambre">
        <div class="row">
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8 molting-spacing">
            <div className="molting-title metropolis-thin">Raízhambre</div>
            <div className="molting-subtitle metropolis-thin">
              2022 - ongoing
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8 molting-text-justify molting-spacing metropolis-extra-light">
            Matlalcuéyetl is an inactive volcano in Tlaxcala, Mexico, located in
            the Nahua and Yuhmu territories. The slope of this volcano harbors
            a coniferous forest that has been devastated for about 50 years by
            illegal logging. In addition, the increase in temperature and the
            imbalance of the ecosystem led to the population growth of a bark
            beetle (Dendroctonus frontalis) that causes the drowning and finally
            the death of the trees. The Ministry of the Environment identifies
            2,600 hectares affected, besides independent organizations report
            6,000, 15% of the forest. <br />
            <br /> The desecration of this ecosystem mirrors a global pattern in
            rural and indigenous territories, where lands are systematically
            destroyed by negligence and corruption. In this forest, logging is
            carried out by some native inhabitants of the surrounding
            communities, a symptom of the lack of job opportunities in the area.
            However, those who are allowing the unchecked advance of the pest,
            causing more loss of trees and greater economic impact with the
            cutting of these, are officials from different state and national
            government institutions allied with sawmills. This panorama does
            not allow us to envision a short-term solution to cease the death of
            the forest. <br /> <br />
            For the Nahua culture, the Matlalcuéyetl forest is the setting where
            many of their myths take place; notions of wisdom, prosperity,
            beauty, and power are attributed to this volcano. It serves as a
            ritual spot where offerings were deposited to pray for good
            harvests. Here, the weather shamans invoke the rain, on its paths
            there are sacred springs and chapels dedicated to female entities,
            it also serves as an observation point to place the body closer to
            the cosmos. <br /> <br />
            <em>Raízhambre</em> is a photographic series that suggests that with
            the destruction of this land, not only trees perish, but animals,
            access to water is scarce, the temperature rises, the rains stop, a
            culture is deprived of its territory and with it, a philosophical
            legacy is lost. ‘Raízhambre’ is a word com- posed of root and
            hunger, alluding to a devoured origin and at the same time echoing
            “raigambre”, a term in Spanish that designates a set of tightly
            entangled plant roots. <br /> <br />
            My perspective originates from San Francisco Tetlanohcan, a Nahua
            community on the slopes of the volcano where my family is from, the
            memory of my blood is saved within this forest.
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-4 raiz-hambre-text-center" style={{paddingTop: '3rem'}}>
            <div className="molting-special-sides-padding metropolis-extra-light">
              Future rains, winds, spring waters, clouds, lightnings, hail, new
              creatures that will come in the form of souls, invisible forces,
              diseases, and death are kept here.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img2} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 raiz-hambre-img-center">
            <Image src={img3} />
          </div>
          <div class="col-12 text-center">
            <Image src={img8} />
          </div>
          <div class="col-12" style={{ textAlign: "center" }}>
            <div className="metropolis-extra-light">
              The trees are drowning from within
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img9} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 raiz-hambre-img-center">
            <Image src={img10} />
          </div>
          <div class="col-12" style={{ textAlign: "center" }}>
            <div className="metropolis-extra-light">
              What afflicts us <br />
              becomes our stick, stone, sigh, <br /> fear and delight.
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img12} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img11} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <Image src={img13} />
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 raiz-hambre-img-center">
            <Image src={img14} />
          </div>
          <div class="col-12" style={{ textAlign: "center" }}>
            <div className="metropolis-extra-light">
              They hide in the night <br />
              vitiated by hunger <br /> they cut off parts of their own body{" "}
              <br /> to satisfy themselves.
            </div>
          </div>
          <div class="col-12">
            <Image src={img22} />
          </div>
          <div
            class="col-12"
            style={{ textAlign: "center", marginBottom: "3rem" }}
          >
            <div className="metropolis-extra-light">
              The trees are drowning from within
            </div>
          </div>
          <div
            class="col-12"
            style={{ textAlign: "center", marginBottom: "3rem" }}
          >
            <div className="raiz-hambre-contact-me metropolis-extra-light">
                This is a short version of this project. <br />
                If you are interested in seeing the full version, please,{" "}
                <a
                  href="mailto:saracuapiom@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="float-end metropolis-extra-light"
                >
                  <em>contact me</em>
                </a>
                .
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RaizHambre;
