import React from "react";
import imageContact from "../../resources/SMC_1.jpg";
import MenuHeader from "../../components/menuHeader";

const Contact = () => {
  return (
    <>
      <MenuHeader current="Contact" />
      <div className="center-screen">
        <div class="container contact-container">
          <div class="row">
            <div class="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7">
              <img className="img-fluid" src={imageContact} />
            </div>
            <div class="col contact-text-container">
              <div className="contact-text float-start">
                <div
                  className="metropolis-regular"
                  style={{ marginBottom: "1rem" }}
                >
                  If you need further information about my projects, my full CV,
                  or would you like to talk about an assignment, collaboration,
                  exhibition, work for sale, and so on, please contact me at:
                </div>
                <div>
                  <a
                    href="mailto:saracuapiom@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="float-end metropolis-extra-light"
                  >
                    saracuapiom@gmail.com
                  </a>
                  <br />
                  <a
                    href="https://www.instagram.com/cuapiomsara/"
                    target="_blank"
                    rel="noreferrer"
                    className="float-end metropolis-extra-light"
                  >
                    ig. cuapiomsara
                  </a>
                </div>
                <div
                  className="metropolis-regular"
                  style={{ marginTop: "3rem" }}
                >
                  CDMX - Tlaxcala, México.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
