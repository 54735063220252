import React from "react";
import { Image } from "react-bootstrap";
import img1 from "./images/SMC-1.jpg";
import img2 from "./images/SMC-2.jpg";
import img3 from "./images/SMC-3.jpg";
import img4 from "./images/SMC-4.jpg";
import img5 from "./images/SMC-5.jpg";
import img6 from "./images/SMC-6.jpg";
import img7 from "./images/SMC-7.jpg";
import img8 from "./images/SMC-8.jpg";
import img9 from "./images/SMC-9.jpg";
import img10 from "./images/SMC-10.jpg";
import img11 from "./images/SMC-11.jpg";
import img12 from "./images/SMC-12.jpg";
import img13 from "./images/SMC-13.jpg";
import img14 from "./images/SMC-14.jpg";
import img15 from "./images/SMC-15.jpg";
import img16 from "./images/SMC-16.jpg";
import img17 from "./images/SMC-17.jpg";
import img18 from "./images/SMC-18.jpg";

const MoltingTime = () => {
  return (
    <>
      <div class="menu metropolis-extra-light">
        <a href="/">Home</a>
        <a href="/menu">Work</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
      <div class="container molting">
        <div class="row">
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8 molting-spacing">
            <div className="molting-title metropolis-thin">MoltingTime</div>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8 molting-text-justify molting-spacing metropolis-extra-light">
            This series was made in collaboration with Santos H. bädi (otomí
            shaman). <br />
            <br /> The northern mountain region of Puebla in Mexico is a
            territory inhabited by the Totonaco, Nahua, Otomí, and Tepehua
            people. Although their population went through an evangelization
            process during the Spanish invasion, the cult of Mesoamerican
            deities is still preserved. <br />
            <br /> Paper is a substantial element in this region, it is made out
            of the bark of the mulberry tree. During a ritual healing process,
            shamans cut out paper deities; these “spirits” of seeds such as
            chili, pumpkin, corn, and beans, among others, communicate with
            forces of good and evil. Each one of these representations has a
            different power to help a sick person. The senses are cultural, and
            the ache state of a body in this area is generally different from
            city sufferings; bad airs, frights, and the evil eye, are some of
            the problems to deal with. <br />
            <br /> My mother lived her youth in the Sierra Norte of Puebla; the
            stories she told me and the constant visits to this place during my
            childhood made me grow up in contact with other notions of illness
            and thus other forms of healing. <br />
            <br /> <em>Molting time</em> is a photo essay that addresses the
            affliction of the body/mind/spirit, and the search to ease it. Such
            a healing process generates movement, introspection, transmutation,
            a connection with what is not necessarily human, it draws upon
            botany knowledge and the philosophize of these cultures around the
            force of nature.
            <br />
            <br />
            <div className="metropolis-regula" style={{ fontSize: ".7rem" }}>
              The iconography shown on this project is collectively owned by
              Totonaco, Nahua, Otomí and Tepehua people.
            </div>
          </div>
          <div class="image-cover col-12 col-xs-12 col-sm-12 col-lg-8">
            <Image src={img1} />
          </div>
          <div class="col">
            <div className="molting-text-center molting-spacing metropolis-regular">
              Turning like this <br /> I came <br />
              like a spinning top from Castile <br /> like a spinning top from
              Castile <br /> this is my turn
              <br /> this is my spin
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <Image src={img2} />
          </div>
          <div class="col-12">
            <div className="molting-text-center metropolis-regular">
              I have come whistling <br /> I have come biting
            </div>
          </div>
          <div class="col raiz-hambre-img-center image-cover">
            <Image src={img3} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              This way I have reached the vein <br /> This way I have reached
              the flesh
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8">
            <Image src={img4} style={{ paddingLeft: "0px !important" }} />
          </div>
          <div class="col molting-text-center-bottom">
            <div className="metropolis-regular">
              I have come to speak to the vein <br /> I have come to speak to
              the flesh. <br /> This is how I settled - you will say <br /> This
              is how I have made myself - you will say
            </div>
          </div>
          <div class="col-12">
            <Image src={img5} />
          </div>
          <div class="col-12">
            <div className=" metropolis-regular">
              This is how I have crossed ravines - you will say <br /> This is
              how I have spread myself among the thorns - you will say <br />
              This is how I have walked through the veins - you will say
            </div>
          </div>
          <div class="col-12">
            <Image src={img6} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              walked through the flesh - you will say <br /> grown on the
              thresholds - you will say
            </div>
          </div>

          <div class="col-12">
            <Image src={img7} />
          </div>

          <div class="col-12">
            <Image src={img8} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              I have already spread my nest - you will say <br /> I have already
              spread my music - you will say <br /> I have already rung my
              rattle - you will say
            </div>
          </div>

          <div class="col-12">
            <Image src={img9} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              This way I have reached the tip of his heart <br /> This way I
              have reached the tip of his ribs <br /> This is how I broke
              through <br /> This is how I settled
            </div>
          </div>

          <div class="col-12">
            <Image src={img10} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              Really, I have already set up my nest <br />
              Really, I have already secretly made it
            </div>
          </div>
          <div class="col"></div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <Image src={img11} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              I am firmly established <br /> I am well embraced <br /> I have
              well reached the tender tip of his heart
            </div>
          </div>
          <div class="col-12">
            <Image src={img12} />
          </div>
          <div class="col-12 raiz-hambre-img-center">
            <Image src={img13} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              I have come to settle me <br /> I have come to make myself
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8">
            <Image src={img14} />
          </div>
          <div class="col molting-text-center-bottom">
            <div className="metropolis-regular">
              I am written <br /> I am a shudder
            </div>
          </div>
          <div class="col-12">
            <Image src={img15} />
          </div>
          <div class="col">
            <div className="metropolis-regular">
              just like a bite <br /> just like a sting <br /> just like a
              Spaniard
            </div>
          </div>
          <div class="col-12">
            <Image src={img16} />
          </div>
          <div class="col-12">
            <div className="metropolis-regular">
              just like an ant from Castile <br />
              like an ant from Castile
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-lg-8">
            <Image src={img17} />
          </div>
          <div class="col molting-text-center-bottom">
            <div className="metropolis-regular">
              I am bite of the tip of his heart <br />
              This is how I spread myself now
            </div>
          </div>
          <div class="col-12">
            <Image src={img18} />
          </div>
          <div class="col-12" style={{ marginBottom: "2rem" }}>
            <div className="metropolis-regular">
              I am truly capable <br /> I am truly powerful.
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </>
  );
};

export default MoltingTime;
