import React from "react";
import imageHome from "../../resources/SMC_3.jpg";
import { Link } from "react-router-dom";
import Title from "../../components/title";

const Home = () => {

  

  return (
    <>
      <Title value="Sara Meneses Cuapio" />
      <div className="home">
        <div className="home-cover mx-auto">
          <img src={imageHome} className="lazy" alt="" />
          <Link style={{ textDecoration: "none", color: "white" }} to="/work">
            Menu
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
