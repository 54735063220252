import React from "react";

const MenuHeader = ({ current }) => {
  var headers = ["Home", "Work", "About", "Contact"];

  const getClassName = (header) => {
    return current === header ? "metropolis-regular" : "metropolis-extra-light";
  };

  return (
    <>
      <div class="menu">
        {headers.map((header) => {
          var root = header === "Home" ? "/" : "/".concat(header.toLowerCase());
          return (
            <a className={getClassName(header)} href={root}>
              {header}
            </a>
          );
        })}
      </div>
    </>
  );
};

export default MenuHeader;
