import "./App.css";
import Home from "./pages/home";
import About from "./pages/about";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/contact";
import RaizHambre from "./pages/work/raizHambre";
import MoltingTime from "./pages/work/moltingTime/MoltingTime";
import TheCount from "./pages/work/theCount/TheCount";
import Menu from "./pages/menu";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
        <Route path="/work" element={<Menu />} />
        <Route path="/raiz-hambre" element={<RaizHambre />} />
        <Route path="/molting-time" element={<MoltingTime />} />
        <Route path="/the-count" element={<TheCount />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
